<template>

<div>
     <!-- Breadcrumb start -->
      <ol class="breadcrumb">
          <li class="breadcrumb-item">Projects</li>
      </ol>
      <!-- Breadcrumb end -->

      <!-- App actions start -->
      <b-form-group>
        <b-button
          to="projects/new"
          class="btn btn-success float-left">
          <b-icon icon="plus-circle" />
          Add Project
        </b-button>
        <b-form-radio-group
              id="btn-radios-project"
              v-model="selected"
             :options="options"
              name="status"
              buttons
              button-variant="primary"
              @change="filterItems"
          >
        </b-form-radio-group>
        <button v-if="selected == 'all'" class="button-legend"><span class="legend"></span><b class="legend">Deactivated Projects</b></button>
      </b-form-group>

  <BMSTable
    :items="filterItems()"
    :fields="fields"
    :sort-by="sortBy"
    :urlBuilder="buildUrl"
  >
    <template #cell(actions)="row">
      <b-button variant="primary" v-if="!row.item.deleted" @click="onDeleteRow(row.item)">
        <b-icon icon="trash"></b-icon>
      </b-button>
      <b-button v-if="row.item.deactivated" @click="onActivate(row.item.id)" variant="primary">
          <b-icon icon="check-circle" />
        </b-button>
        <b-button v-if="!row.item.deactivated" @click="onDeactivate(row.item.id)" variant="primary">
          <b-icon icon="dash-circle" />
        </b-button>
    </template>

  </BMSTable>

</div>
</template>

<script>

import { getAllProjects, deleteProject, activateProject, deactivateProject } from '@/api/projects.api'
import BMSTable from '@/components/BMSTable';
import confirmationModal from '@/api/confirmation'
import crudMixin from '@/api/crud'

export default {
  mixins: [crudMixin, confirmationModal],
  data() {
    return {
      fields: [{
        key: 'client.company',
        label: 'Company',
        sortable: true,
      }, {
        key: 'name',
        label: 'Project',
        sortable: true,
      }, {
        label: 'Action',
        key: 'actions'
      }],
      options : [
        {text : 'Active', value : 'active'},
        {text : 'All', value : 'all'}
      ],
      items: null,
      filter: null,
      sortBy: 'client.company',
      selected:'all',
    }
  },
  mounted() {
    this.getProjects();
  },
  methods: {
    getProjects() {
        this.call(
            getAllProjects(),
            (res) => {
                this.items = res.data.projects
            }
        )
    },
    buildUrl(project){
      return { name: 'Project', params: { id: project.id }}
    },
    onDeleteRow: function(item){
      this.confirmModal().then((result) => {
        if (result){
        this.deleteObject(
            deleteProject(item.id),
            () => {
                this.$router.go()
            }
        )
      }
      })
    },
    onActivate: function(id){
      // confirmModal(message, okTitle, cancelTitle, okVariant {default: danger})
      this.confirmModal("Are you sure?", "ACTIVATE", "CANCEL", "success").then((result) => {
        if (result){
        this.saveObject(
          activateProject(id),
          () =>{
            this.$router.go()
          }
        )
      }
      })
   },
   onDeactivate: function(id){
      // confirmModal(message, okTitle, cancelTitle, okVariant {default: danger})
      this.confirmModal("Are you sure?", "DEACTIVATE", "CANCEL", "success").then((result) => {
        if (result){
        this.saveObject(
          deactivateProject(id),
          () =>{
            this.$router.go()
          }
        )
      }
      })
   },
    filterItems(){
      if(this.selected != 'all'){
        return this.items.filter(item => item.deactivated == false)
      } 
      return this.items
    }
  },
  components: {
      BMSTable
  }
}

</script>
<style>
span.legend {
border: 1px solid #ccc; 
float: left; 
width: 20px; 
height: 20px; 
margin: 4px;
background-color: #a5a5a5;
}
button.button-legend{
  border: none;
  pointer-events: none;
}
.btn-primary{
  background-color: white;
  color: #4F2B76;
}
.btn .btn-primary .active{
  color: #fff;
  background-color: #4F2B76;
  border-color: #4F2B76;
}

</style>
